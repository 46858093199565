import React from 'react'
import { Link } from 'react-router-dom'

const KuroLink = ({children, to, classList}) => {
    return (
        <Link className={classList} to={to}>
            {children}
        </Link>
    )
}

export default KuroLink